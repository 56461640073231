.contact-form {
    box-shadow: var(--shadow-2);
    height: auto;
    width: 60rem !important;
    /* width: 100% !important; */
    border-radius: var(--radius-2);
    padding: 2rem;
    border: var(--border-dashed);
}

.overlay {
    width: 100%;
    height: 100%;
    background-color: var(--dark);
    opacity: .6;
    position: absolute;
}

.contact-top {
    height: auto;
    width: 100%;
}

.contact-top-image {
    width: 100%;
    height: 60vh;
    background-image: url('../../../assets/images/contact/help.webp');
    background-repeat: no-repeat;
    background-size: cover;
    object-fit: cover;
    border-radius: 0 0 20% 20%;
    box-shadow: var(--shadow-x);
    filter: brightness(0.4);
    background-attachment: fixed;
    background-position: center;
}

.contact-top-cards {
    margin: -6rem 0 0 0;
    z-index: 9;
    position: relative;
}

.contact-top-cards-item {
    background-color: var(--light);
    width: 260px !important;
    height: 215px !important;
    box-shadow: var(--shadow-x);
    border-radius: var(--radius-2);
    margin: 2rem 1.3rem;
    padding: 7px 0;
}

.cards-item-text p,
.cards-item-btn {
    transition: var(--transition);
}


.cards-item-btn {
    width: 7rem;
    height: 2.4rem;
    color: var(--dark) !important;
    font-size: 18p !important;
    /* padding: 5px 10px !important; */
    border-radius: var(--radius);
    border: 1px solid var(--light-dark) !important;
}

.cards-item-text p {
    color: var(--clr-gold);
    font-size: 19px;
}

.cards-item-tel {
    font-size: 1.5rem !important;
    letter-spacing: 1px;
}

.cards-item-btn:hover,
.cards-item-tel:hover {
    color: var(--orange) !important;
}


.cards-item-icon {
    color: var(--clr-gold);
    font-size: 3.2rem;
}

.cards-item-social-icon {
    font-size: 32px;
    color: var(--dark);
    margin: 0 5px 1px 5px;
    transition: var(--transition);
}

.cards-item-social-image {
    width: 40px;
    height: 100%;
    margin: 0 5px;
    transition: var(--transition);
}

.cards-item-social-icon:hover,
.cards-item-social-image:hover {
    color: var(--clr-gold);
    transform: scale(1.1);
}

.cards-item-social-icon-fb:hover {
    border: 2px solid var(--clr-gold);
}

.cards-item-social-icon-fb {
    border: 2px solid var(--dark);
    border-radius: 8px;
}

@media screen and (max-width:1460px) {
    .contact-top-image {
        background-attachment: inherit;
        background-position: inherit;
    }
}

@media screen and (max-width:992px) {
    .contact-form {
        width: 100% !important
    }

    .contact-top-image {
        background-attachment: inherit;
        background-position: inherit;
    }

    .contact-top-image {
        height: 50vh;
    }
}

@media screen and (max-width:578px) {
    .contact-top-image {
        height: 35vh;
    }
}


.login-icon {
    height: 100%;
    width: 40px;
}

.form-check-content {
    font-size: 12px !important;
    color: var(--blue);
    transition: var(--transition) !important;
}

.form-check-content:hover {
    color: var(--light-blue);
}

input:focus {
    border: 2px solid var(--blue) !important;
    outline: none !important;
    box-shadow: none !important;
    /* box-shadow: 0 0 0 0.25rem #e7695d2a !important; */
}

.text-opacity {
    opacity: .5 !important;
}

.contact-title {
    font-size: 3.2rem !important;
    margin: -1rem 0 0 0;
}

.contact-container {
    margin: 0 0 20rem 0;
}


.contact-text {
    color: var(--orange);
    font-size: 1.5rem;
}

.contact-logo img {
    height: 100%;
    width: 45px;
}

.contact-logo {
    height: 100%;
    width: 68px;
    border: 2px solid var(--light-dark);
    padding: 10px;
    border-radius: 50%;
}


.contact-message {
    font-family: var(--font-family-0) !important;
    opacity: 0.99 !important;
    letter-spacing: .02rem;
}

.contact-message:focus {
    border: 2px solid var(--blue) !important;
    box-shadow: none !important;
}

.contact-info {
    color: var(--dark) !important;
    font-size: 16px !important;
    letter-spacing: .6px;
}