footer {
    position: fixed;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 999;
    width: 100%;
    background-color: var(--light-2);
    height: 16rem !important;
    /* height: 16rem !important; */
    box-shadow: var(--shadow-2);
}

.footer-container {
    height: 16rem !important;
}

.condition {
    letter-spacing: 1px;
    text-transform: uppercase;
}

.footer-social-icon {
    font-size: 25px !important;
}

.condition {
    width: 65rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.condition-utisl::after {
    content: "" !important;
    position: absolute !important;
    height: 3rem !important;
    width: 1px !important;
    color: var(--dark) !important;
    background-color: var(--dark) !important;
    margin: -13px 0 0 44px !important;
}

/* .empty{
    width: 2px;
  } */

.copyright {
    opacity: .8;
    font-size: 14px;
}

.copyright-container {
    background-color: var(--light);
    position: relative;
    bottom: 0;
}

.copyright-row {
    width: 65rem;
    background-color: var(--light);
    position: relative;
    bottom: 0;
}

.footer-images img {
    width: 100px;
}

.w-80 {
    width: 100rem !important;
}

.w-70 {
    width: 70% !important;
}

@media (max-width: 992px) {
    footer .container {
        max-width: 800px !important;
    }
}

@media screen and (max-width:992px) {
    footer {
        height: 19rem !important;
    }

    .copyright-container {
        padding-bottom: 1rem !important;
    }
}

@media screen and (max-width:875px) {
    .condition {
        width: 50rem !important;
    }
}

@media screen and (max-width:810px) {
    .condition {
        width: 47rem !important;
    }
}

@media screen and (max-width:810px) {
    .condition {
        display: grid !important;
        justify-content: space-around !important;
        align-items: center !important;
    }
}
