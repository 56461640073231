/* SubShopping Card Box */
.subshopping-card-box {
    position: absolute;
    top: calc(100% + 2rem);
    right: 13rem;
    /* right: 22%; */
    transform: translateX(50%);
    width: 25rem;
    background: var(--light);
    border-radius: 10px;
    box-shadow: var(--shadow-2);
    font-family: var(--font-family-2);
    opacity: 0;
    max-height: 0;
    overflow: hidden;
    transition: opacity 0.7s ease, max-height 0.7s ease, margin 0.7s ease, all 0.7s ease;
    margin: -4rem 0 2rem 0rem !important;
    padding: 1rem 0 0 0;
}

.subshopping-card-box.show {
    opacity: 1;
    max-height: 100vh;
    margin: -32px 0 13rem 0rem !important;
}

@media screen and (max-width:768px) {
    .subshopping-card-box.show {
        margin: -7rem 0 13rem 0rem !important;
        border: 1px solid var(--light-dark-2);
    }
}

.card-content {
    max-height: 22rem;
    min-height: 15rem;
    overflow: scroll;
}

.subshopping-card-message {
    background-color: var(--blue);
    padding: 1rem 0;
    border-radius: var(--radius);
    color: var(--light);
    box-shadow: var(--shadow-2);
    transition: var(--transition) !important;
}

.subshopping-card-value {
    color: var(--orange) !important;
    margin: -12px 0 0 -3px !important;
    position: absolute;
    font-family: var(--font-family-2);
    background-color: var(--blue);
    width: 22px;
    height: 22px;
    border-radius: 50%;
    text-align: center;
}

.subshopping-card-content {
    color: var(--light) !important;
    margin: 0.7px 0px 0px -0.7px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 13px;
}

.subshopping-card-empty-image {
    width: 40%;
}

.card-checkout {
    margin: 0px 0 0px 0;
    box-shadow: var(--shadow);
    padding: 10px 0 0 0;
}

.card-checkout-btn {
    /* margin-bottom: 0.25rem !important; */
    font-size: 15px !important;
    color: var(--blue) !important;
    letter-spacing: .5px;
    transition: var(--transition) !important;
}

.card-checkout-btn:hover {
    color: var(--orange) !important;
    /* margin: 0 0 0 -2rem !important; */
    margin-left: -2rem !important;
}

.card-checkout-icon {
    color: var(--orange);
    font-size: 22px;
    transition: var(--transition) !important;
    margin: -3px 0 0 0px;
}

.card-checkout-btn:hover .card-checkout-icon {
    transform: translate(8.7rem, 0%);
    color: var(--blue);
}

.subshopping-card-item {
    border-radius: var(--radius);
    box-shadow: var(--shadow);
    height: 6rem;
    margin: 0rem 0 0rem 0;
}

.subshopping-card-item:not(:last-child) {
    margin: 0rem 0 0.5rem 0;
}

.subshopping-card-item-img {
    height: 100%;
    /* height: 72px; */
    width: 100%;
    border-radius: var(--radius);
    object-fit: scale-down;
    overflow: hidden;
    padding: 5px 0;
}

.subshopping-card-item-details {
    color: var(--blue);
}

.subshopping-card-item-title {
    font-size: 15px;
}

.subshopping-card-item-prix {
    font-size: 15px;
    color: var(--orange);
    margin: 3px 0 0 0;
}

.trash-icon {
    color: var(--orange);
    font-size: 20px;
    box-shadow: var(--shadow-3);
    border-radius: var(--radius);
    width: 25px;
    height: 25px;
    cursor: pointer;
    transition: var(--transition) !important;
    margin: 0 0 0 1px;
}

.trash-icon:hover {
    transform: scale(1.1);
}

.trash {
    margin: 0 0 0 -15px;
    transform: translate(12%, -17%);
}

.subshopping-card-close-icon {
    color: var(--orange);
    font-size: 17px;
    margin: -1px -3px 0 -3px;
}

.card-badge {
    width: 23px !important;
    height: 23px !important;
    margin: 0px 0px 0px 2px !important;
    padding: 3px 0 0 0 !important;
    font-size: 15px !important;
    font-family: var(--font-family-3);
}

.command-btn {
    background-color: var(--light);
    padding: 1rem 7rem;
    border: 2px solid var(--light-dark-2);
    border-radius: var(--radius);
    color: var(--blue);
    box-shadow: var(--shadow-2);
    transition: var(--transition) !important;
    margin: 10px 0;
    letter-spacing: 1px;
}

.command-btn:hover {
    color: var(--light);
    background-color: var(--dark) !important;
}

.total-price {
    color: var(--blue);
    letter-spacing: 1px;
    margin: 1rem 0 0 0;
}

hr {
    background-color: var(--light-dark-2) !important;
}

.total-price::before {
    content: "" !important;
    position: absolute !important;
    height: 1px !important;
    width: 94% !important;
    color: var(--dark) !important;
    background-color: var(--light-dark-2) !important;
    padding: 0px 0px 0px 0px;
    margin: 2px 0 0px -26px;
}