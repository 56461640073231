.review-rating .star-svg{
    width: 26px !important;
}

.text-blue{
    color: var(--blue);
}

/* .reviews-title::after{
        content: "";
        position: absolute;
        width: 0;
        height: 3px;
        bottom: 0;
        left: 0;
        background: #258f67;

} */

.reviews-title{
    position: relative;
}

.reviews-title::after{
    content: '';
position: absolute;
width: 100%;
height: 2px;
background-color: var(--blue);
left: 0;
bottom: 0px;
border-radius: 10px;
}