.details-marques-container {
    margin-bottom: 18rem !important;
    /* max-width: 1560px !important; */
}

.details-marques-row {
    height: auto;
    /* height: 25rem; */
    /* max-height: 25rem; */
    border-radius: var(--radius-2);
    /* box-shadow: var(--shadow-2); */
    /* background-color: #272b3a09; */
    /* background-color: #272b3a04; */
}

/* .details-marques-col-img {
    max-height: 19rem !important;
} */

.details-marques-col-img,
.details-marques-content {
    height: 21rem !important;
}

.details-marques-content {
    width: 25rem;
    /* height: 100%; */
    overflow: hidden;
    border-radius: var(--radius-2);
    border: 2px solid var(--light-dark-2);
    transition: var(--transition);
}

.details-marques-image {
    width: 100%;
    height: 18rem;
}

.details-marques-title {
    width: 100%;
    height: 3rem;
    bottom: 0;
    position: sticky;
    background-color: #272b3a04 !important;
    box-shadow: var(--shadow-5);
    padding: 7px  0 0 0 !important;
    transition: var(--transition);
}

.details-marques-content:hover .details-marques-title{
    background-color: var(--clr-gold) !important;
    color: var(--light) !important;

}

.details-marques-img {
    width: 100%;
    height: 80%;
    border-radius: var(--radius-2);
    object-fit: contain;
    transition: var(--transition);
}

.details-marques-img:hover {
    transform: scale(1.1);
}

.details-marques-desc {
    font-size: 1.15rem !important;
}

/* Media Query */

/* @media screen and (min-width:1650px) {
    .details-marques-container {
        max-width: 1560px !important;
    }

} */

@media screen and (max-width:1200px) {
    .details-marques-desc {
        font-size: 1rem !important;
    }

}

@media screen and (max-width:992px) {
    .details-marques-desc {
        font-size: 1.15rem !important;
    }

    /* .details-marques-content {
        width: 100%;
    } */

    /* .details-marques-row {
        height: auto;
    } */

    /* .details-marques-col-img {
        height: 25rem !important;
    } */

}

@media screen and (max-width:578px) {
    /* .details-marques-container {
        max-width: 370px !important;
    } */

    /* .details-marques-col-img {
        height: 15rem !important;
    } */

}