/* .order-item {
    border-radius: var(--radius); 
    border: var(--border-dashed);
    box-shadow: var(--shadow);
    margin: 0 0 1rem 0;
} */

.order-item-content{
    /* height: 2.5rem; */
    min-height: 1.5rem;
    max-height: 2.8rem;
    position: relative;
    overflow: hidden;
    font-family: var(--font-family-0);
    font-weight: 600;
}

@media screen and (max-width:768px) {
    .order-item-content{
        height: 18rem;
    }
}

.order-item-img {
    width: 100%;
    margin: 0 0 0 0.6rem;
    object-fit: contain;
    overflow: hidden;
}

.order-item-quantity {
    box-shadow: var(--shadow-2);
    height: 41px;
    width: 6rem;
    border-radius: var(--radius-2);
}

.order-item-quantity-value{
    font-size: 18px;
    font-family: var(--font-family-2);
    font-weight: 600;
}

.order-item-quantity-icon {
    cursor: pointer;
    color: var(--dark);
    font-size: 25px;
}