.submit-btn{
    color: var(--light) !important;
    font-size: 14px !important;
    background-color: var(--clr-gold) !important;
    border: none !important;
    box-shadow: var(--shadow-1);
    padding: 14px 3rem !important;
    margin: -2rem 0 0 0 !important;
    text-transform: uppercase !important;
    letter-spacing: .8px !important;
    transition: var(--transition) !important;
}

.submit-btn:hover{
    background-color: var(--dark) !important;
    /* background-color: var(--light-blue) !important; */
}

.addtocart-icon{
    font-size: 20px !important;
    margin: 0px 9px 1px -7px !important;
    transform: scale(1.3) !important;
}