.container-centent {
    max-width: 90%;
    overflow-y: scroll;
}

.marque-navigation .swiper-slide {
    width: 170px !important;
}


.marque-swiper-content {
    width: 130px;
    height: 180px;
}

.marque-swiper {
    width: 200px !important;
    height: 180px !important;
    /* height: 150px !important; */
    animation: borderChange 7s linear infinite;
    /* border: 3px solid var(--light); */
    border-radius: 20px;
    /* box-shadow: var(--shadow-x); */
    overflow: hidden;
    z-index: 999;
    transition: var(--transition);
    position: relative;
    margin-top: 1.2rem;
}

.marque-navigation .swiper-pagination {
    margin: 0rem 0 0px 0 !important;
    height: 1rem !important;
    display: none !important;
}

.marque-swiper-img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    transition: var(--transition);
}

/* .marque-swiper {
    transform: scale(.6);
} */

.marque-swiper:hover {
    transform: scale(1.2);
}


.marque-swiper-overlay {
    position: absolute;
    background-color: var(--clr-dark);
    width: 100%;
    opacity: .8;
    transform: translate(-50%, 0);
    bottom: 0;
    height: 0rem;
    left: 50%;
    color: var(--clr-light);
    transition: var(--transition);
}


.marque-swiper-overlay-before {
    position: absolute;
    background-color: var(--clr-dark);
    width: 100%;
    opacity: .5;
    transform: translate(-50%, 0);
    top: 0;
    height: 100%;
    left: 50%;
    color: var(--clr-light);
    transition: var(--transition);
}

.marque-swiper:hover .marque-swiper-overlay-before,
.marque-image-container:hover .marque-swiper-overlay-before {
    height: 0%;
}

.marque-swiper:hover .marque-swiper-overlay,
.marque-image-container:hover .marque-swiper-overlay {
    height: 100%;
    opacity: .9;
}

.marque-swiper-title {
    position: absolute;
    color: var(--clr-dark);
    width: 100%;
    margin: 0rem 0 -5px 0;
    bottom: 0px;
    height: 2rem;
}

.marque-swiper-title p {
    font-size: 15px !important;
    text-align: center;
}



@keyframes borderChange {
    0% {
        border-color: var(--light);
    }

    50% {
        border-color: var(--clr-light-primary);
    }

    70% {
        border-color: var(--clr-light-warning);
    }

    100% {
        border-color: var(--clr-light-info);
    }
}

.swiper-button-prev,
.swiper-button-next {
    display: none !important;
    background-color: var(--light) !important;
    border-radius: 50% !important;
    width: 43px !important;
    height: 43px !important;
    border: 1px solid #272b3a26 !important;
    transition: var(--transition);
}

.swiper-button-prev:after,
.swiper-button-next:after {
    color: var(--dark) !important;
    font-size: 21px !important;
    font-weight: 600 !important;
    transition: var(--transition);
}

.swiper-button-prev:hover,
.swiper-button-next:hover {
    border: 1px solid var(--clr-gold) !important;
}

.swiper-button-prev:hover .swiper-button-prev:after,
.swiper-button-next:hover .swiper-button-next:after {
    color: var(--light) !important;
    font-size: 27px !important;
}

/* @media screen and (max-width: 768px) {
    .marque-navigation .swiper-slide {
        width: 350px !important;
    }

    .marque-swiper-content {
        width: 100% !important;
        height: 225px !important;
    }

    .marque-swiper {
        width: 100% !important;
        height: 160px !important;
    }

    .swiper-slide {
        margin: 0px 10px;
    }
} */