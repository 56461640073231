.pack-details {
    position: relative !important;
    /* max-width: 80px; */
}

.pack-details-content {
    margin: 0 0 5rem 0 !important;
}

@media screen and (max-width:992px) {
    .pack-details-content {
        margin: 0 0 9rem 0 !important;
    }

}

.pack-details-title {
    font-family: var(--font-family-1) !important;
    margin: 15px 0 10px 0 !important;
}

.pack-details-prix {
    color: var(--blue);
    font-family: var(--font-family-1) !important;
    font-size: 23px;
}

.pack-details-desc-parent {
    overflow: scroll;
    max-height: 35rem;
}

.pack-details-desc {
    font-family: var(--font-family-3) !important;
    color: var(--gray-2) !important;
    font-size: 20px;
    overflow: hidden;
}

/* 
.pack-details-desc img {
    display: none;
} */

.pack-details-total {
    color: var(--blue) !important;
}

.pack-details-total-text,
.pack-details-total-price {
    font-size: 21px !important;
}

.pack-details .container {
    cursor: default !important;
}

.pack-details-image {
    max-width: 80% !important;
}

@media screen and (max-width:567px) {
    .pack-details-image {
        max-width: 100% !important;
    }
}


/* .pack-video iframe{
    border-radius: var(--radius-2) !important;
    box-shadow: var(--shadow-1) !important;
    transform: scale(80%) !important;
} */

.pack-video {
    position: relative !important;
    padding-bottom: 56% !important;
    height: 0 !important;
    overflow: hidden !important;
    border-radius: var(--radius-2) !important;
    box-shadow: var(--shadow-1) !important;
    transform: scale(100%) !important;
}

.video-container {
    position: absolute !important;
    top: 0 !important;
    left: 0 !important;
    width: 100% !important;
    height: 100% !important;
}

.video-container iframe {
    width: 100% !important;
    height: 100% !important;
    border-radius: var(--radius-2) !important;
    box-shadow: var(--shadow-1) !important;
}


@media screen and (max-width:768px) {
    .pack-video {
        transform: scale(130%) !important;
    }
}

.share-into {
    color: var(--blue);
}

.share-icon {
    transition: 1.4s !important;
}

.share-icon svg {
    box-shadow: var(--shadow-1) !important;
    border-radius: 50% !important;
}

.share-icon:hover {
    transform: rotate(360deg);
}

.share-btn {
    color: var(--blue);
    margin: 0px 0 7px 0;
    font-size: 27px;
}

.share-btn-text {
    color: var(--blue);
}

.pack-description {
    /* display: none; */
    overflow: hidden;
}

.pack-description img {
    transform: scale(50%) !important;
    overflow: hidden;
    /* justify-content: start; */
    /* display: fle; */
    /* display: none; */
    /* position: absolute; */
}


/*
.bigImage img {
    width: 100%;
    border-radius: 5px;
}

.smallImages {
    display: flex;
    gap: 20px;
    margin-top: 20px;
}

.smallImages img {
    width: 20%;
    border-radius: 5px;
    cursor: pointer;
}  */

.desc-col {
    margin: 0 0 15rem 0 !important;
}

.desc-section .row {
    margin: 0 0 1rem 0 !important;
}


.tab-content {
    font-family: var(--font-family-3) !important;
    color: var(--gray-2) !important;
    font-size: 19px;
}

.review-form {
    font-family: var(--font-family-0) !important;
}

.review-avatar {
    width: 80px;
}

.star-svg {
    width: 30px;
}

.review-box-btn {
    margin: 0 0 8rem 0 !important;
}

.error-message {
    width: 100%;
    margin-top: 0.25rem;
    font-size: .875em;
    color: #dc3545;
}