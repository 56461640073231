.product-img,
.product-title,
.pack-img,
.pack-title .view,
.eye-icon {
    transition: var(--transition) !important;
    cursor: pointer;
}

.product-item {
    /* height: 27.5rem; */
    height: 24.5rem;
    border: var(--border-dashed);
    border-radius: 10px;
    box-shadow: var(--shadow-2);
    transform: scale(.95);
    max-width: 330px !important;
    overflow: hidden;
    background-color: var(--light);
}

.product-img {
    /* width: 95% !important; */
    /* object-fit: cover;
    height: 80%;
    width: auto; */

    object-fit: contain;
    height: 80%;
    width: 100%;
}

.product-img:hover {
    transform: scale(1.1);
}

/* .product-image {
    position: relative;
    height: 21rem;
} */

.product-image {
    height: 17rem;
    position: relative;
    margin: 5px 0 -1.6rem 0 !important;
}

.product-title {
    font-size: 17px !important;
    font-family: var(--font-family-0) !important;
    opacity: 0.9 !important;
    font-weight: 600;
    line-height: 1.5rem;
}


.product-info {
    margin: -8px 0 0 0;
    height: 4rem;
    /* display: flex;
    justify-content: center;
    align-items: center; */
}

.product-price {
    color: var(--blue);
    font-size: 21px;
    /* margin: 0 0 3.5rem 0; */
}

.discount-price {
    color: var(--red) !important;
    font-size: 17px !important;
    /* opacity: .8 !important; */
    text-decoration: line-through;
    text-decoration-style: inherit;
    text-decoration-color: var(--red);
    font-family: var(--font-family-4);

}

.discount-tag {
    width: 3.1rem;
    right: 0px;
    z-index: 9;
    /* animation: rotateAnimation 2s linear infinite;
    animation-delay: 5s; */
}

.discount-price-icon {
    margin: -3px 0px 0px -17px;
    width: 20%;
    position: absolute;
}

.discount-value-old {
    /* width: 4rem;  */
    /* height: 22px;  */
    left: 0px;
    padding: 5px 10px;
    z-index: 9;
    border-bottom-right-radius: var(--radius);
    background-color: var(--red);
    color: var(--light);
    /* background-color: var(--orange); */
    font-size: 19px;
}

.discount-value {
    background-color: var(--red);
    border-bottom-right-radius: var(--radius);
    color: var(--light);
    font-size: 20px;
    top: 0;
    left: 0;
    padding: 4px 8px;
    font-family: var(--font-family-0);
    z-index: 9;
    font-weight: 600;
}




@keyframes rotateAnimation {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

/* .product-details:hover .product-price {
    opacity: 0 !important;
    margin: -18px 0 0 0 !important;
  } */


.view {
    position: relative;
    margin: -4.3rem 0 1rem 0;
    width: 50px;
    height: 32px;
    border-radius: 15px;
    box-shadow: var(--shadow-5) !important;
    background-color: var(--dark);
}

.eye-icon {
    font-size: 26px;
    opacity: 0.9;
    color: var(--light);
}

/* .view:hover {
    background-color: var(--light);
} */

.view:hover .eye-icon {
    /* color: var(--dark); */
    transform: scale(1.15);
}


.add-to-card-container {
    transform: scale(.85);
    min-height: 4.2rem;
    max-height: 4.2rem;
    transition: var(--transition) !important;
}

.add-to-card-container:hover .addtocart-btn {
    transform: scale(1.1);
}

.add-to-card {
    text-transform: uppercase !important;
    color: var(--light) !important;
    font-size: 14px;
    text-align: center;
    background-color: var(--blue);
    padding: 10px 20px;
    border-radius: var(--radius);
    letter-spacing: .8px;
}

.add-to-card:hover {
    background-color: var(--dark) !important;
}

.p-discount-price {
    margin: -5px 0 0 0 !important;
}


@media screen and (max-width:768px) and (min-width:576px) {
    .populare-products .product-info {
        margin: -2.6rem 0 0 0;
    }

    .populare-products .view {
        margin: -8rem 0 1rem 0;
    }

    .populare-products .product-price {
        margin: 0 0 0rem 0;
        /* margin: 0 0 3.5rem 0; */
    }

    .populare-products .submit-btn {
        font-size: 12px !important;
        padding: 14px 2rem !important;
        margin: -2rem 0 0.3rem 0 !important;
        transform: scale(1.1);
    }
}

.loading-discount {
    transform: scale(.8);
    top: 6px;
    position: relative;
    right: -3px;
}

.shopping-cart-icon-parent {
    width: 50px;
    height: 50px;
    border: 2px solid var(--clr-gold);
    border-radius: 50%;
    padding: 9px 3px 7px 7px;
    transition: background-color .7s;
}

.shopping-cart-icon {
    color: var(--clr-gold);
    font-size: 40px;
    transition: color .7s;
}

.shopping-cart-icon-parent:hover {
    background-color: var(--clr-gold);

}

.shopping-cart-icon-parent:hover .shopping-cart-icon {
    color: var(--light);

}

.shopping-cart-add-to {
    z-index: 9999;
}

.view-details {
    border-bottom-right-radius: var(--radius);
    color: var(--light);
    font-size: 20px;
    top: 0;
    right: 0;
    padding: 4px 8px;
    font-family: var(--font-family-0);
    z-index: 9;
    font-weight: 600;
}

.view-details-content {
    position: relative;
    margin: 4px 0 0 0;
    width: 34px;
    height: 34px;
    border-radius: 50%;
    box-shadow: var(--shadow-5) !important;
    border: 0.06cm solid #272b3ae3;
    transition: var(--transition);
}

.view-details-eye-icon {
    font-size: 22px;
    opacity: 0.99;
    color: #272b3ae3;
    transition: var(--transition);
}

.view-details:hover .view-details-content {
    background-color: #272b3ae3;

}

.view-details:hover .view-details-eye-icon {
    color: var(--light);
}


/* @media screen and (max-width:576px) {
    .product-item {
        max-width: 400px !important;
    }

    .product-img {
        width: 78% !important;
    }
} */


/* @media screen and (min-width:1600px) {
    .product-item {
      width: 312px !important;
    }
  } */

/* .product-details:hover .add-to-card {
    opacity: 1 !important;
    margin: 3px 0 0 0 !important;
  } */


/* .product-details:hover .view {
    display: flex;
  } */


/* @media screen and (max-width:540px) {
    .product-details .product-img {
        height: 15rem !important;
    }
} */

/* .view {
    position: relative;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    box-shadow: var(--shadow-2);
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .eye-icon {
    font-size: 24px;
    color: #000000;
  } */


/* .add-to-card {
  color: var(--gray);
  font-size: 13px;
  font-weight: 700;
  margin: 3px 0 0 0 !important;
  opacity: 1 !important;
  transition: var(--transition) !important;
  z-index: 99999 !important;
} */