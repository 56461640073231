.terms {
    margin: 0 0 20rem 0 !important;
    font-family: var(--font-family-0);
    opacity: .9;
}

.website-name {
    color: var(--blue);
}

.fs-18 {
    font-size: 18px;
}