:root {
  --shadow: 0 0.1rem 1.5rem 0 rgb(22 39 86 / 10%);
  --shadow-0: 0 0 10px rgba(0, 0, 0, 0.2);
  --shadow-1: 0 0.1rem 0.9rem 0 rgb(22 39 86 / 20%);
  --shadow-2: rgb(0 17 51 / 7%) 0px 3px 15px;
  --shadow-3: 0 1.2rem -1.5rem 0.8rem rgba(22, 39, 86, 0.459);
  --shadow-5: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  --shadow-x: 0 8px 32px 0 rgba(8, 10, 43, 0.12);
  /* --shadow-3: 0 0.2rem -1rem 0 rgb(22 39 86 / 10%); */
  --radius: 5px;
  --radius-2: 10px;
  --font-family-0: "Varela Round", sans-serif !important;
  --font-family-1: "NeueEinstellung", "sans-serif" !important;
  --font-family-2: "Radnika-Medium", "sans-serif" !important;
  --font-family-3: "Cedora", "sans-serif" !important;
  --light: #fff;
  --clr-light: #fff;
  --light-2: #f4f4f4;
  --orange: #f25c4f;
  /* --orange: #e7685d; */
  --blue: #5e7bc9;
  --clr-blue: #5e7bc9;
  /* --blue: #025464; */
  /* --blue: #146C94; */
  --light-blue-1: #146b94de;
  --gray: rgba(84, 84, 84, 0.8) !important;
  --gray-2: #545454 !important;
  --gray-x: #252525 !important;
  --gray-3: #5454549f !important;
  --light-orange: #e7695dd0;
  --light-orange-1: #e7695d75;
  --light-blue: rgba(94, 123, 201, 0.7);
  --light-blue-0: #146b9442;
  --red: #fd384f;
  --clr-gold: #ae861b;
  --clr-primary: #009ef7;
  --clr-success: #50cd89;
  --clr-warning: #ffc700;
  --clr-danger: #f1416c;
  --clr-info: #7239ea;
  --clr-light-gold: #fffdf7d4;
  --clr-light-primary: #f1faff;
  --clr-light-success: #e8fff3;
  --clr-light-warning: #fff8dd;
  --clr-light-danger: #fff5f8;
  --clr-light-info: #f8f5ff;
  /* --dark: #000; */
  --dark: #272B3A;
  --light-dark: #272b3a2a;
  --light-dark-2: #272b3a11;
  --radius: 5px;
  /* --border-dashed: 1px var(--light-dark) dashed !important; */
  --border-dashed: 1px var(--light-blue-0) dashed !important;
  --border-dashed-1: 1px var(--light-blue-0) dashed !important;
  --border-solid: 1px var(--light-dark-0) solid !important;
  --category-img-lg: 380px;
  --category-img-md: 335px;
  --transition: all .7s !important;
}

@font-face {
  font-family: 'NeueEinstellung';
  src: url(../src/assets/fonts/NeueEinstellungBold.ttf);
}

@font-face {
  font-family: 'Radnika-Medium';
  src: url(../src/assets/fonts/Radnika-Medium.otf);
}

@font-face {
  font-family: 'Cedora';
  src: url(../src/assets/fonts/Cedora-RegularStd.otf);
}

@font-face {
  font-family: 'Varela Round';
  src: url(../src//assets//fonts/VarelaRound-Regular.ttf);
}

body {
  overflow: visible !important;
}

body,
.app,
.carousel {
  font-family: var(--font-family-1) !important;
}

/* body,
.app {
  background-color: #fbfbfb1c !important;
} */

button:hover {
  background: none !important;
}

.m-0 {
  margin: 0 !important;
}

.m-hr {
  margin: 2px 0 5px 0 !important;
  padding: 4px 0 !important;
}

.hr-tag {
  opacity: .05 !important;
}

.invalid-feedback {
  font-family: var(--font-family-0) !important;
  font-weight: 600 !important;
}

.left-50 {
  left: 50% !important;
}

.pos-r {
  position: relative !important;
}

.c-pointer {
  cursor: pointer !important;
}

.hb-link {
  text-decoration: none !important;
  border: none;
  color: var(--dark);
}

.text-orange-hover:hover {
  color: var(--orange);
}


.clr-orange {
  color: var(--orange) !important;
}

.clr-blue {
  color: var(--blue) !important;
}

.clr-dark {
  color: var(--dark) !important;
}

.clr-red {
  color: var(--red) !important;
}

.clr-info {
  color: #7239ea !important;
}

.clr-success {
  color: var(--clr-success) !important;
}

.clr-danger {
  color: var(--clr-danger) !important;
}

.ox-hidden {
  overflow-x: hidden !important;
}

.d-contents {
  display: contents !important;
}

.bg-dark {
  background-color: var(--dark) !important;
}

.ff-0 {
  font-family: var(--font-family-0) !important;
}

.ff-3 {
  font-family: var(--font-family-3) !important;
}

.fs-x {
  font-size: 23px !important;
}

.clr-gray {
  color: var(--gray-x) !important;
}

.clr-gold {
  color: var(--clr-gold) !important;
}

.fw-600 {
  font-weight: 600 !important;
}

.mb-6-rem {
  margin: 0 0 6rem 0 !important;
}

.fs-18 {
  font-size: 18px !important;
}

.mn-8 {
  margin: -8rem 0 0 0 !important;
}

.hb-btn {
  outline: none !important;
  border: none !important;
  margin: 0 !important;
  padding: 0 !important;
  background: none !important;
}

.mn-13 {
  margin: 2rem 0 0 2rem !important;
}

.mn-14 {
  margin: -14rem 0 0 0 !important;
}

.mt-6 {
  margin: 4.8rem 0 0 0 !important;
}

.m-filter {
  margin: 12px 0 -13px 0 !important;
}

.m-filter-icon {
  margin: 0 0 0 -24px !important;
}

.m-filter-row {
  margin-right: -64px !important;
}

.m-filter-icon-active {
  margin: 0 0 0 -13px !important;
}

.m-filter-row-active {
  margin-right: -52px !important;
}

.fix-width {
  right: 13px;
  position: absolute;
}

.m-grid-filter {
  margin-top: 33px !important;
}

.hb-ms-4 {
  margin-left: 1.5rem !important;
}

.hb-me-3 {
  margin-right: 0rem !important;
}

.filter-label {
  padding: 4px 0px 0px 0px !important;
  font-size: 16px !important;
}

.height-100 {
  height: 100% !important;
}

.d-block-ruby {
  display: block ruby !important;
}

.text-cap {
  text-transform: capitalize !important;
}

.text-upper {
  text-transform: uppercase !important;
}


.d-flex-center {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}

.d-flex-start {
  display: flex !important;
  justify-content: flex-start !important;
  align-items: center !important;
}

.d-flex-end {
  display: flex !important;
  justify-content: flex-end !important;
  align-items: center !important;
}

.d-flex-between-center {
  display: flex !important;
  justify-content: space-between !important;
  align-items: center !important;
}



/* ================================= */


.icon {
  margin: 0 0 3px 0 !important;
}

.custom-form-check {
  font-size: 30px !important;
  margin: -3px 5px 0px -26px !important;
  cursor: pointer;
}



.products {
  /* height: 60vh !important; */
  height: auto !important;
  margin-bottom: 4rem !important;
  /* margin: 6.7rem 0 0 0 !important; */
}

@media screen and (max-width:1600px) {
  .products {
    height: 64vh !important;
  }
}

@media screen and (max-width:1400px) {
  .products {
    height: 64vh !important;
  }
}

@media screen and (max-width:1200px) {
  .products {
    height: 97vh !important;
  }
}

@media screen and (max-width:1051px) {
  .products {
    height: 89vh !important;
  }
}

@media screen and (max-width:880px) {
  .products {
    height: 101vh !important;
  }
}


@media screen and (max-width:768px) {
  .products {
    height: 107vh !important;
  }

  .hb-ms-4 {
    margin-left: 0rem !important;
  }

  .hb-me-3 {
    margin-right: 1rem !important;
  }
}

@media screen and (max-width:578px) {
  .auth-container-max-w {
    max-width: 400px !important;
    margin-left: auto !important;
    margin-right: auto !important;
    transform: scale(.94) !important;
  }
}

@media screen and (max-width:575px) {
  .products {
    height: 102vh !important;
  }

  .max-sm-w {
    max-width: 360px !important;
  }

}

.product-container {
  max-width: 1200px !important;
}



@media screen and (max-width:1630px) {
  .products {
    transform: scale(0.9) !important;
  }
}

@media screen and (max-width:1430px) {
  .products {
    transform: scale(0.8) !important;
  }
}

@media screen and (max-width:1050px) {
  .products {
    transform: scale(0.7) !important;
  }

  .product-container {
    max-width: 1400px !important;
  }

}

@media screen and (max-width:880px) {
  .products {
    transform: scale(0.9) !important;
  }

  .product-container {
    max-width: 1400px !important;
  }

  .product-details {
    height: 25rem !important;
  }

}



/* Modal */

::-webkit-scrollbar-track,
.shopping-card-items::-webkit-scrollbar-track {
  /* -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.151) !important; */
  border-radius: 10px !important;
  /* background-color: #F5F5F5 !important; */
}

::-webkit-scrollbar,
.shopping-card-items::-webkit-scrollbar {
  width: 8px !important;
  height: 30% !important;
  /* background-color: #F5F5F5 !important; */
}

::-webkit-scrollbar-thumb,
.shopping-card-items::-webkit-scrollbar-thumb {
  border-radius: 10px !important;
  /* -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3) !important; */
  background-color: var(--light-dark) !important;
}


::-wwebkit-scrollbar {
  width: 6px !important
    /* Width of the scrollbar */
}

::-wwebkit-scrollbar-track {
  background-color: transparent !important
    /* Color of the track */
}

::-wwebkit-scrollbar-thumb {
  background-color: transparent !important
    /* Color of the scroll thumb */
}


.storybook-button {
  font-family: 'Nunito Sans', 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-weight: 700;
  border: 0;
  border-radius: 3em;
  cursor: pointer;
  display: inline-block;
  line-height: 1;
  padding: 4px 12px;
  font-size: 13px;
}

.storybook-button--primary {
  color: white;
  background-color: #1ea7fd;
}



/* ================================== */


.btn-loading {
  height: 3.8rem;
  width: 19rem;
  background-color: var(--orange);
  padding: 14px 40px;
  color: #fff;
  text-transform: uppercase;
  letter-spacing: 2px;
  cursor: pointer;
  border-radius: 10px;
  border: 2px dashed var(--orange);
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
  transition: .4s;
  text-decoration: none;
}

.btn-loading span:last-child {
  display: none;
}

.btn-loading:hover {
  transition: .4s;
  border: 2px dashed var(--orange);
  background-color: #fff;
  color: var(--orange);
}

/* .btn-loading:active {
  background-color: #87dbd0;
} */



/* ====================== */


/* ================================================= */


/* .dropdown-itemm::after {
  content: "";
  height: 1px;
    background-color: var(--dark);
    width: 90%;
    position: absolute;
    border-bottom: 2px var(--orange) solid !important;
} */


/*
  .dropdown-submenu li::after{
    content: "";
      background-image: url('./assets/images/slider/greater-than-symbol.png');
      display: block;
      width: 4rem;
      height: 4rem;
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;
      position: absolute;
      color: #fff;
      transform: scale(.75);
      margin: 0px 0px 0 -3rem;
      transition: var(--transition) !important;
  } */



@media screen and (min-width:992px) {
  .m-logo {
    margin: 0 32px 0 -29px !important;
  }

  .m-search {
    margin: 0 -14px 0 0 !important;
  }
}


/*  */


.alice-carousel__next-btn-wrapper,
.alice-carousel__prev-btn-wrapper {
  display: flex !important;
  top: 41px !important;
  position: absolute !important;
  background-color: var(--dark) !important;
  padding: 1px 9px !important;
  border-radius: var(--radius) !important;
}

.alice-carousel__next-btn-wrapper {
  justify-content: right !important;
  right: -54px !important;
}


.alice-carousel__prev-btn-wrapper {
  justify-content: left !important;
  left: -54px;
}

.alice-carousel__next-btn-item,
.alice-carousel__prev-btn-item {
  color: var(--light) !important;
  font-size: 20px !important;
}


@media screen and (max-width:768px) {

  .alice-carousel__prev-btn,
  .alice-carousel__next-btn {
    display: none !important;
  }
}


/*HB-Badge */

.hb-badge {
  padding: 5px 5px;
  border-radius: var(--radius);
  font-size: 14px;
  max-width: 7.5rem !important;
  min-width: 6rem !important;
  display: block;
  text-align: center;
}


.hb-badge-success {
  background-color: #e8fff3;
  color: #50cd89;
}

.hb-badge-info {
  background-color: var(--clr-light-info);
  color: var(--clr-info);
}


.hb-badge-danger {
  background-color: #fff5f8;
  color: #f1416c;
}


.hb-badge-primary {
  background-color: #f1faff;
  color: #009ef7;
}


.hb-badge-warning {
  background-color: #fff8dd;
  color: #ffc700;
}

.hb-small-btn {
  padding: 8px 10px !important;
  transition: var(--transition);
}

.hb-icon {
  font-size: 22px;
  margin: -2px 0 0 0;
}

.hb-danger-btn {
  background-color: var(--clr-light-danger) !important;
}

.hb-danger-btn {
  background-color: var(--clr-light-danger) !important;
  color: var(--clr-danger) !important;
  transition: var(--transition);
}

.hb-danger-btn:hover {
  background-color: var(--clr-danger) !important;
  color: var(--light) !important;
}

.hb-danger-in-btn {
  background-color: var(--clr-danger) !important;
  color: var(--light) !important;
  transition: var(--transition);
}

.hb-danger-in-btn:hover {
  background-color: var(--clr-light-danger) !important;
  color: var(--clr-danger) !important;
}

.hb-success-btn {
  background-color: var(--clr-success) !important;
  color: var(--light) !important;
  transition: var(--transition);
}

.hb-success-btn:hover {
  background-color: var(--clr-light-success) !important;
  color: var(--clr-success) !important;
}

.hb-primary-btn {
  background-color: var(--clr-light-primary) !important;
}

.hb-danger-btn:hover {
  background-color: var(--clr-danger) !important;
}

.hb-danger-btn:hover .hb-danger-icon {
  color: var(--light) !important;
}


.hb-danger-icon {
  color: var(--clr-danger) !important;
}

.hb-primary-icon {
  color: var(--clr-primary) !important;
}

.hb-primary-btn:hover {
  background-color: var(--clr-primary) !important;
}

.hb-primary-btn:hover .hb-primary-icon {
  color: var(--light) !important;
}

.hb-info-btn {
  background-color: var(--clr-light-info) !important;
}

.hb-info-btn:hover {
  background-color: var(--clr-info) !important;
}

.hb-info-icon {
  color: var(--clr-info) !important;
}

.hb-info-btn:hover .hb-info-icon {
  color: var(--light) !important;
}


.td-title {
  max-width: 20rem;
  white-space: break-spaces;
}

.td-min-w {
  min-width: 18rem !important;
}