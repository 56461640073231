.styles-module_floatingWhatsapp__yWF5K {
    z-index: 999999;
}

.styles-module_chatFooter__TGv0P form {
    bottom: 0;
    position: absolute;
    width: 23rem;
}

.styles-module_chatHeader__gaeOA {
    margin: -5px 0 2px 0;
}

.styles-module_chatHeader__gaeOA .styles-module_avatar__afcc4 img {
    background-color: #fff;
    transform: scale(.9);
}

.styles-module_whatsappButton__tVits,
.styles-module_whatsappChatBox__MdQ5U.styles-module_open__R8NqR {
    left: 2rem !important;
    box-shadow: var(--shadow-1) !important;
}

.styles-module_chatFooter__TGv0P form .styles-module_buttonSend__kXjja svg {
    vertical-align: middle !important;
    fill: #075e54 !important;
}

.styles-module_chatFooter__TGv0P {
    background: none !important;
}

.styles-module_whatsappChatBox__MdQ5U.styles-module_open__R8NqR,
.styles-module_whatsappChatBox__MdQ5U {
    left: 2rem !important
}

.styles-module_whatsappButton__tVits svg {
    fill: #25d366 !important
}

.styles-module_whatsappButton__tVits {
    background-color: #fff !important;
}