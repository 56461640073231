/*
©Raj Rajhans
rajrajhans.com
Day #4 : 30 Days CSS
*/
.animation-bar-1 {
    position: absolute;
    width: 101%;
    display: block;
    border-radius: 30px;
    bottom: 0;
    line-height: 0;
    left: -4px;
}

.animation-bar-1 span {
    position: relative;
    display: inline-block;
    vertical-align: middle;
    height: 11px;
    border-radius: 10px 0 0 10px;
    overflow: hidden;
    border-radius: 30px;
    background-size: 100%;
    background: #f1faff linear-gradient(to bottom, #009ef7, #009cf7ad);
    /* background: #f56982 linear-gradient(to bottom, #f2395a, #b90c2b); */
    animation: progress-anim-1 10s infinite linear;
}

.animation-bar-1 span:after {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    content: "";
    background-image: linear-gradient(45deg, #ffffff 25%,
            rgba(0, 0, 0, 0) 25%,
            rgba(0, 0, 0, 0) 50%,
            #ffffff 50%,
            #ffffff 75%,
            rgba(0, 0, 0, 0) 75%,
            rgba(0, 0, 0, 0));
    background-size: 30px 30px;
    opacity: 0.3;
    animation: progress-anim-1-after 0.5s infinite linear;
}

@keyframes progress-anim-1 {
    0% {
        width: 100%;
    }

    50% {
        width: 100%;
    }

    100% {
        width: 100%;
    }
}

@keyframes progress-anim-1-after {
    0% {
        background-position: 0 100%;
    }

    100% {
        background-position: 30px 100%;
    }
}

.animated-loading {
    position: relative;
    height: 3.5rem;
    background-color: var(--light);
    box-shadow: var(--shadow-2);
    overflow: hidden;
    border-radius: var(--radius);
    text-align: center;
    padding: 3px 0 0 0;
}