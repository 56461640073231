.top-header-container {
    background-color: var(--light) !important;
    height: 50px !important;
    color: var(--dark) !important;
    font-family: var(--font-family-2) !important;
    border-bottom: 1px solid var(--light-dark-2);
    position: relative;
    /* z-index: 99; */
}

.top-header-container .top-container {
    margin: 0 2rem !important;

}

.top-containerr {
    max-width: 1700px !important;
}

.info>a,
.social>a,
.time {
    transition: var(--transition) !important;
}

.info>a:hover,
.icon:hover {
    color: var(--orange) !important;
}

.social-media-content {
    width: 0px;
    /* height: 11rem; */
    height: 8.5rem;
    position: absolute;
    overflow: hidden !important;
    background-color: var(--light);
    box-shadow: var(--shadow-1);
    border-radius: var(--radius);
    padding: 3px 0 0 0px;
    transition: var(--transition);
}

.is-social-media-open {
    visibility: visible;
    z-index: 999 !important;
    margin: 3rem 0px 0 -1%;
    width: 50px;
    padding: 3px 0 0 7px;
}

.is-social-media-close {
    /* height: 0px; */
    visibility: hidden;
    z-index: 0 !important;
    margin: 2.7rem 0px 0 5.4rem;

}

.social-media-image {
    width: 40px;
    margin: 0 0 3px 0;
}

.social-media-content .social-icon {
    margin-top: .4rem;
    border: 2px solid var(--dark);
    border-radius: 50%;
    width: 35px;
    height: 35px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 5px 0px 1px 1px;
    transition: var(--transition);
}


.social-media-content .social-icon:hover {
    border: 2px solid var(--orange);
    color: var(--orange) !important;
}

.social-media-grid-icon {
    z-index: 999 !important;
}

@media screen and (max-width: 1400px) {
    .top-header-container {
        height: 50px !important;
    }
}


@media screen and (min-width:992px) {
    .m-info {
        margin: 0 0 0 -16px !important;
    }
    .m-social {
        margin: 0 -24px 0 -3px !important;
    }
}