.shopping-card-item {
    border-radius: var(--radius);
    border: var(--border-dashed);
    box-shadow: var(--shadow);
    margin: 0 0 1rem 0;
}

.shopping-card-item-content {
    height: 7rem;
    position: relative;
    overflow: hidden;
}

.shopping-card-item-image{
    height: 6rem !important;
}

@media screen and (max-width:768px) {
    .shopping-card-item-content {
        height: 14rem;
    }
}

@media screen and (max-width:578px) {
    .shopping-card-item-content {
        height: 15rem;
    }
}

.shopping-card-item-img {
    width: 100%;
    margin: 0 0 0 0.6rem;
    object-fit: scale-down !important;
    overflow: hidden;
    height: 100%;
    padding: 5px 0;
}

.shopping-card-item-quantity {
    box-shadow: var(--shadow-2);
    height: 41px;
    width: 6rem;
    border-radius: var(--radius-2);
}

.shopping-card-item-quantity-value {
    font-size: 18px;
    font-family: var(--font-family-2);
    font-weight: 600;
}

.shopping-card-item-quantity-icon {
    cursor: pointer;
    color: var(--dark);
    font-size: 25px;
}