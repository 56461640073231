.checkout-items {
    height: auto;
    /* box-shadow: var(--shadow); */
    border-radius: var(--radius);
    padding: 10px;
    color: var(--blue) !important;
    margin: 0 0 3rem 0;
}

.adresse-items{
        /* height: 60vh; */
        height: auto;
        overflow: auto;
}

.checkout-card-items {
    height: auto;
    box-shadow: var(--shadow);
    border-radius: var(--radius-2);
    padding: 10px;
    color: var(--blue) !important;
    margin: 0 0 3rem 0;
}

.checkout-items-centent{
    height: auto;
    max-height: 60rem;
    overflow: auto;
}
