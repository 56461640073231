.categories {
    margin: 5rem 0 0 0 !important;
}

.category-img {
    width: var(--category-img-lg);
    border-radius: var(--radius-2);
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: var(--transition) !important;

    /* height: var(--category-img-lg);
    box-shadow: var(--shadow-2); */


    height: auto;
    /* box-shadow: var(--shadow-x); */

}

.category-img img {
    max-height: 100%;
    max-width: 100%;
    min-height: 11rem;
    object-fit: cover;
    border-radius: var(--radius-2);
    transition: var(--transition) !important;
}


/* .category-img:hover img {
    transform: scale(1.2);
} */

.category-content:last-child {
    margin: 0 0 15rem 0;
}

.grid-gallery-details {
    margin: 0 0 19rem 0 !important;
}

.grid-gallery-details {
    margin: 0 0 19rem 0 !important;
}

@media screen and (max-width:1630px) {
    .category-details {
        margin: 0 0 5rem 0 !important;
    }
}

@media screen and (max-width:1430px) {
    .category-details {
        margin: 0 0 7rem 0 !important;
    }
}

@media screen and (max-width:1050px) {
    .category-details {
        margin: 0 0 9rem 0 !important;
    }
}

@media screen and (max-width:880px) {
    .category-details {
        margin: 0 0 4rem 0 !important;
    }
}

.category-details {
    margin: 0 0 3rem 0;
}

.category-title {
    color: var(--dark);
    font-size: 17px !important;
    transition: var(--transition) !important;
}

.category-title:hover {
    opacity: .8;
}



@media screen and (max-width:1130px) {
    .category-img {
        height: 100%;
        width: 100%;
    }
}

.grid-gallery-overlay {
    position: absolute;
    background-color: var(--dark);
    width: 100%;
    opacity: .8;
    transform: translate(-50%, 0);
    bottom: 0;
    border-radius: var(--radius) var(--radius) var(--radius-2) var(--radius-2);
    height: 3rem;
    left: 50%;
    color: var(--light);
    text-align: center;
    justify-content: center;
    display: flex;
    align-items: center;
    transition: var(--transition);
    cursor: pointer;
}

.grid-gallery-centent:hover .grid-gallery-overlay {
    height: 100%;
    border-radius: var(--radius-2);
    opacity: .9;
}


.grid-gallery-img {
    box-shadow: var(--shadow-x);
    border-radius: var(--radius-2);

    min-height: 12rem !important;
    object-fit: cover;
}

.grid-gallery-title {
    letter-spacing: 1px;
    margin: 5px 0 0 0;
    cursor: pointer;
}

/* @media screen and (max-width: 578px) {
    .grid-gallery-details {
        margin: 0 0 19rem 0 !important;
    }
} */

@media screen and (max-width: 578px) {
    .grid-gallery-details {
        padding: 1px 20px !important;
    }
}

/* .category-title::after {
    content: '';
    display: inline-block;
    width: 64%;
    height: 2px;
    background-color: var(--light-dark);
    margin-top: -10px;
    text-align: center;
  } */



/* .category-img {
    height: 400px;
    width: 400px;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  */